exports.components = {
  "component---src-pages-2021-data-book-js": () => import("./../../../src/pages/2021/data-book.js" /* webpackChunkName: "component---src-pages-2021-data-book-js" */),
  "component---src-pages-2021-innovate-js": () => import("./../../../src/pages/2021/innovate.js" /* webpackChunkName: "component---src-pages-2021-innovate-js" */),
  "component---src-pages-2021-live-js": () => import("./../../../src/pages/2021/live.js" /* webpackChunkName: "component---src-pages-2021-live-js" */),
  "component---src-pages-2021-partner-day-js": () => import("./../../../src/pages/2021/partner-day.js" /* webpackChunkName: "component---src-pages-2021-partner-day-js" */),
  "component---src-pages-2022-data-book-js": () => import("./../../../src/pages/2022/data-book.js" /* webpackChunkName: "component---src-pages-2022-data-book-js" */),
  "component---src-pages-2022-live-js": () => import("./../../../src/pages/2022/live.js" /* webpackChunkName: "component---src-pages-2022-live-js" */),
  "component---src-pages-2022-partner-day-js": () => import("./../../../src/pages/2022/partner-day.js" /* webpackChunkName: "component---src-pages-2022-partner-day-js" */),
  "component---src-pages-2022-partner-day-lightning-talks-js": () => import("./../../../src/pages/2022/partner-day-lightning-talks.js" /* webpackChunkName: "component---src-pages-2022-partner-day-lightning-talks-js" */),
  "component---src-pages-2023-data-book-js": () => import("./../../../src/pages/2023/data-book.js" /* webpackChunkName: "component---src-pages-2023-data-book-js" */),
  "component---src-pages-2023-holiday-marketing-ebook-js": () => import("./../../../src/pages/2023/holiday-marketing-ebook.js" /* webpackChunkName: "component---src-pages-2023-holiday-marketing-ebook-js" */),
  "component---src-pages-2023-live-js": () => import("./../../../src/pages/2023/live.js" /* webpackChunkName: "component---src-pages-2023-live-js" */),
  "component---src-pages-2024-live-js": () => import("./../../../src/pages/2024/live.js" /* webpackChunkName: "component---src-pages-2024-live-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-article-index-js": () => import("./../../../src/pages/article/index.js" /* webpackChunkName: "component---src-pages-article-index-js" */),
  "component---src-pages-blog-c-7-feature-index-js": () => import("./../../../src/pages/blog/c7-feature/index.js" /* webpackChunkName: "component---src-pages-blog-c-7-feature-index-js" */),
  "component---src-pages-blog-case-studies-index-js": () => import("./../../../src/pages/blog/case-studies/index.js" /* webpackChunkName: "component---src-pages-blog-case-studies-index-js" */),
  "component---src-pages-blog-ecommerce-index-js": () => import("./../../../src/pages/blog/ecommerce/index.js" /* webpackChunkName: "component---src-pages-blog-ecommerce-index-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-marketing-index-js": () => import("./../../../src/pages/blog/marketing/index.js" /* webpackChunkName: "component---src-pages-blog-marketing-index-js" */),
  "component---src-pages-blog-news-index-js": () => import("./../../../src/pages/blog/news/index.js" /* webpackChunkName: "component---src-pages-blog-news-index-js" */),
  "component---src-pages-blog-tasting-room-index-js": () => import("./../../../src/pages/blog/tasting-room/index.js" /* webpackChunkName: "component---src-pages-blog-tasting-room-index-js" */),
  "component---src-pages-blog-wine-club-index-js": () => import("./../../../src/pages/blog/wine-club/index.js" /* webpackChunkName: "component---src-pages-blog-wine-club-index-js" */),
  "component---src-pages-book-your-demo-aus-nz-index-js": () => import("./../../../src/pages/book-your-demo-aus-nz/index.js" /* webpackChunkName: "component---src-pages-book-your-demo-aus-nz-index-js" */),
  "component---src-pages-book-your-demo-aus-nz-request-js": () => import("./../../../src/pages/book-your-demo-aus-nz/request.js" /* webpackChunkName: "component---src-pages-book-your-demo-aus-nz-request-js" */),
  "component---src-pages-book-your-demo-index-js": () => import("./../../../src/pages/book-your-demo/index.js" /* webpackChunkName: "component---src-pages-book-your-demo-index-js" */),
  "component---src-pages-book-your-demo-request-js": () => import("./../../../src/pages/book-your-demo/request.js" /* webpackChunkName: "component---src-pages-book-your-demo-request-js" */),
  "component---src-pages-c-7-payments-index-js": () => import("./../../../src/pages/c7-payments/index.js" /* webpackChunkName: "component---src-pages-c-7-payments-index-js" */),
  "component---src-pages-careers-index-js": () => import("./../../../src/pages/careers/index.js" /* webpackChunkName: "component---src-pages-careers-index-js" */),
  "component---src-pages-commerce-7-acquires-vinespring-index-js": () => import("./../../../src/pages/commerce7-acquires-vinespring/index.js" /* webpackChunkName: "component---src-pages-commerce-7-acquires-vinespring-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-customers-australia-and-new-zealand-australia-js": () => import("./../../../src/pages/customers/australia-and-new-zealand/australia.js" /* webpackChunkName: "component---src-pages-customers-australia-and-new-zealand-australia-js" */),
  "component---src-pages-customers-australia-and-new-zealand-index-js": () => import("./../../../src/pages/customers/australia-and-new-zealand/index.js" /* webpackChunkName: "component---src-pages-customers-australia-and-new-zealand-index-js" */),
  "component---src-pages-customers-australia-and-new-zealand-new-zealand-js": () => import("./../../../src/pages/customers/australia-and-new-zealand/new-zealand.js" /* webpackChunkName: "component---src-pages-customers-australia-and-new-zealand-new-zealand-js" */),
  "component---src-pages-customers-canada-index-js": () => import("./../../../src/pages/customers/canada/index.js" /* webpackChunkName: "component---src-pages-customers-canada-index-js" */),
  "component---src-pages-customers-canada-niagara-js": () => import("./../../../src/pages/customers/canada/niagara.js" /* webpackChunkName: "component---src-pages-customers-canada-niagara-js" */),
  "component---src-pages-customers-canada-okanagan-js": () => import("./../../../src/pages/customers/canada/okanagan.js" /* webpackChunkName: "component---src-pages-customers-canada-okanagan-js" */),
  "component---src-pages-customers-enterprise-js": () => import("./../../../src/pages/customers/enterprise.js" /* webpackChunkName: "component---src-pages-customers-enterprise-js" */),
  "component---src-pages-customers-europe-js": () => import("./../../../src/pages/customers/europe.js" /* webpackChunkName: "component---src-pages-customers-europe-js" */),
  "component---src-pages-customers-index-js": () => import("./../../../src/pages/customers/index.js" /* webpackChunkName: "component---src-pages-customers-index-js" */),
  "component---src-pages-customers-midsize-js": () => import("./../../../src/pages/customers/midsize.js" /* webpackChunkName: "component---src-pages-customers-midsize-js" */),
  "component---src-pages-customers-small-js": () => import("./../../../src/pages/customers/small.js" /* webpackChunkName: "component---src-pages-customers-small-js" */),
  "component---src-pages-customers-south-africa-js": () => import("./../../../src/pages/customers/south-africa.js" /* webpackChunkName: "component---src-pages-customers-south-africa-js" */),
  "component---src-pages-customers-united-states-central-coast-js": () => import("./../../../src/pages/customers/united-states/central-coast.js" /* webpackChunkName: "component---src-pages-customers-united-states-central-coast-js" */),
  "component---src-pages-customers-united-states-east-coast-js": () => import("./../../../src/pages/customers/united-states/east-coast.js" /* webpackChunkName: "component---src-pages-customers-united-states-east-coast-js" */),
  "component---src-pages-customers-united-states-index-js": () => import("./../../../src/pages/customers/united-states/index.js" /* webpackChunkName: "component---src-pages-customers-united-states-index-js" */),
  "component---src-pages-customers-united-states-napa-valley-sonoma-js": () => import("./../../../src/pages/customers/united-states/napa-valley-sonoma.js" /* webpackChunkName: "component---src-pages-customers-united-states-napa-valley-sonoma-js" */),
  "component---src-pages-customers-united-states-pacific-northwest-js": () => import("./../../../src/pages/customers/united-states/pacific-northwest.js" /* webpackChunkName: "component---src-pages-customers-united-states-pacific-northwest-js" */),
  "component---src-pages-customers-united-states-texas-js": () => import("./../../../src/pages/customers/united-states/texas.js" /* webpackChunkName: "component---src-pages-customers-united-states-texas-js" */),
  "component---src-pages-demo-index-js": () => import("./../../../src/pages/demo/index.js" /* webpackChunkName: "component---src-pages-demo-index-js" */),
  "component---src-pages-demo-request-index-js": () => import("./../../../src/pages/demo-request/index.js" /* webpackChunkName: "component---src-pages-demo-request-index-js" */),
  "component---src-pages-dtc-deep-dive-bingham-family-vineyards-js": () => import("./../../../src/pages/dtc-deep-dive/bingham-family-vineyards.js" /* webpackChunkName: "component---src-pages-dtc-deep-dive-bingham-family-vineyards-js" */),
  "component---src-pages-error-js": () => import("./../../../src/pages/error.js" /* webpackChunkName: "component---src-pages-error-js" */),
  "component---src-pages-get-started-au-index-js": () => import("./../../../src/pages/get-started/au/index.js" /* webpackChunkName: "component---src-pages-get-started-au-index-js" */),
  "component---src-pages-get-started-ca-index-js": () => import("./../../../src/pages/get-started/ca/index.js" /* webpackChunkName: "component---src-pages-get-started-ca-index-js" */),
  "component---src-pages-get-started-eu-index-js": () => import("./../../../src/pages/get-started/eu/index.js" /* webpackChunkName: "component---src-pages-get-started-eu-index-js" */),
  "component---src-pages-get-started-index-js": () => import("./../../../src/pages/get-started/index.js" /* webpackChunkName: "component---src-pages-get-started-index-js" */),
  "component---src-pages-get-started-nz-index-js": () => import("./../../../src/pages/get-started/nz/index.js" /* webpackChunkName: "component---src-pages-get-started-nz-index-js" */),
  "component---src-pages-get-started-uk-index-js": () => import("./../../../src/pages/get-started/uk/index.js" /* webpackChunkName: "component---src-pages-get-started-uk-index-js" */),
  "component---src-pages-get-started-us-index-js": () => import("./../../../src/pages/get-started/us/index.js" /* webpackChunkName: "component---src-pages-get-started-us-index-js" */),
  "component---src-pages-get-started-za-index-js": () => import("./../../../src/pages/get-started/za/index.js" /* webpackChunkName: "component---src-pages-get-started-za-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-on-demand-demo-apis-js": () => import("./../../../src/pages/on-demand-demo/apis.js" /* webpackChunkName: "component---src-pages-on-demand-demo-apis-js" */),
  "component---src-pages-on-demand-demo-club-js": () => import("./../../../src/pages/on-demand-demo/club.js" /* webpackChunkName: "component---src-pages-on-demand-demo-club-js" */),
  "component---src-pages-on-demand-demo-design-js": () => import("./../../../src/pages/on-demand-demo/design.js" /* webpackChunkName: "component---src-pages-on-demand-demo-design-js" */),
  "component---src-pages-on-demand-demo-ecommerce-js": () => import("./../../../src/pages/on-demand-demo/ecommerce.js" /* webpackChunkName: "component---src-pages-on-demand-demo-ecommerce-js" */),
  "component---src-pages-on-demand-demo-index-js": () => import("./../../../src/pages/on-demand-demo/index.js" /* webpackChunkName: "component---src-pages-on-demand-demo-index-js" */),
  "component---src-pages-on-demand-demo-pos-js": () => import("./../../../src/pages/on-demand-demo/pos.js" /* webpackChunkName: "component---src-pages-on-demand-demo-pos-js" */),
  "component---src-pages-on-demand-demo-reservations-js": () => import("./../../../src/pages/on-demand-demo/reservations.js" /* webpackChunkName: "component---src-pages-on-demand-demo-reservations-js" */),
  "component---src-pages-partners-apply-app-js": () => import("./../../../src/pages/partners/apply/app.js" /* webpackChunkName: "component---src-pages-partners-apply-app-js" */),
  "component---src-pages-partners-apply-design-js": () => import("./../../../src/pages/partners/apply/design.js" /* webpackChunkName: "component---src-pages-partners-apply-design-js" */),
  "component---src-pages-partners-apply-js": () => import("./../../../src/pages/partners/apply.js" /* webpackChunkName: "component---src-pages-partners-apply-js" */),
  "component---src-pages-partners-become-a-partner-app-js": () => import("./../../../src/pages/partners/become-a-partner/app.js" /* webpackChunkName: "component---src-pages-partners-become-a-partner-app-js" */),
  "component---src-pages-partners-become-a-partner-design-js": () => import("./../../../src/pages/partners/become-a-partner/design.js" /* webpackChunkName: "component---src-pages-partners-become-a-partner-design-js" */),
  "component---src-pages-partners-become-a-partner-index-js": () => import("./../../../src/pages/partners/become-a-partner/index.js" /* webpackChunkName: "component---src-pages-partners-become-a-partner-index-js" */),
  "component---src-pages-partners-contact-index-js": () => import("./../../../src/pages/partners/contact/index.js" /* webpackChunkName: "component---src-pages-partners-contact-index-js" */),
  "component---src-pages-partners-designers-js": () => import("./../../../src/pages/partners/designers.js" /* webpackChunkName: "component---src-pages-partners-designers-js" */),
  "component---src-pages-partners-index-js": () => import("./../../../src/pages/partners/index.js" /* webpackChunkName: "component---src-pages-partners-index-js" */),
  "component---src-pages-press-index-js": () => import("./../../../src/pages/press/index.js" /* webpackChunkName: "component---src-pages-press-index-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-pricing-au-index-js": () => import("./../../../src/pages/pricing/au/index.js" /* webpackChunkName: "component---src-pages-pricing-au-index-js" */),
  "component---src-pages-pricing-ca-index-js": () => import("./../../../src/pages/pricing/ca/index.js" /* webpackChunkName: "component---src-pages-pricing-ca-index-js" */),
  "component---src-pages-pricing-eu-index-js": () => import("./../../../src/pages/pricing/eu/index.js" /* webpackChunkName: "component---src-pages-pricing-eu-index-js" */),
  "component---src-pages-pricing-index-js": () => import("./../../../src/pages/pricing/index.js" /* webpackChunkName: "component---src-pages-pricing-index-js" */),
  "component---src-pages-pricing-nz-index-js": () => import("./../../../src/pages/pricing/nz/index.js" /* webpackChunkName: "component---src-pages-pricing-nz-index-js" */),
  "component---src-pages-pricing-payments-index-js": () => import("./../../../src/pages/pricing/payments/index.js" /* webpackChunkName: "component---src-pages-pricing-payments-index-js" */),
  "component---src-pages-pricing-templates-index-js": () => import("./../../../src/pages/pricing/templates/index.js" /* webpackChunkName: "component---src-pages-pricing-templates-index-js" */),
  "component---src-pages-pricing-uk-index-js": () => import("./../../../src/pages/pricing/uk/index.js" /* webpackChunkName: "component---src-pages-pricing-uk-index-js" */),
  "component---src-pages-pricing-us-index-js": () => import("./../../../src/pages/pricing/us/index.js" /* webpackChunkName: "component---src-pages-pricing-us-index-js" */),
  "component---src-pages-pricing-za-index-js": () => import("./../../../src/pages/pricing/za/index.js" /* webpackChunkName: "component---src-pages-pricing-za-index-js" */),
  "component---src-pages-privacy-index-js": () => import("./../../../src/pages/privacy/index.js" /* webpackChunkName: "component---src-pages-privacy-index-js" */),
  "component---src-pages-quickbooks-webinar-index-js": () => import("./../../../src/pages/quickbooks-webinar/index.js" /* webpackChunkName: "component---src-pages-quickbooks-webinar-index-js" */),
  "component---src-pages-resources-index-js": () => import("./../../../src/pages/resources/index.js" /* webpackChunkName: "component---src-pages-resources-index-js" */),
  "component---src-pages-solutions-club-js": () => import("./../../../src/pages/solutions/club.js" /* webpackChunkName: "component---src-pages-solutions-club-js" */),
  "component---src-pages-solutions-crm-js": () => import("./../../../src/pages/solutions/crm.js" /* webpackChunkName: "component---src-pages-solutions-crm-js" */),
  "component---src-pages-solutions-ecommerce-js": () => import("./../../../src/pages/solutions/ecommerce.js" /* webpackChunkName: "component---src-pages-solutions-ecommerce-js" */),
  "component---src-pages-solutions-index-js": () => import("./../../../src/pages/solutions/index.js" /* webpackChunkName: "component---src-pages-solutions-index-js" */),
  "component---src-pages-solutions-payments-js": () => import("./../../../src/pages/solutions/payments.js" /* webpackChunkName: "component---src-pages-solutions-payments-js" */),
  "component---src-pages-solutions-pos-js": () => import("./../../../src/pages/solutions/pos.js" /* webpackChunkName: "component---src-pages-solutions-pos-js" */),
  "component---src-pages-solutions-reservations-js": () => import("./../../../src/pages/solutions/reservations.js" /* webpackChunkName: "component---src-pages-solutions-reservations-js" */),
  "component---src-pages-subscribe-success-index-js": () => import("./../../../src/pages/subscribe-success/index.js" /* webpackChunkName: "component---src-pages-subscribe-success-index-js" */),
  "component---src-pages-terms-index-js": () => import("./../../../src/pages/terms/index.js" /* webpackChunkName: "component---src-pages-terms-index-js" */),
  "component---src-pages-wise-webinar-index-js": () => import("./../../../src/pages/wise-webinar/index.js" /* webpackChunkName: "component---src-pages-wise-webinar-index-js" */),
  "component---src-templates-app-list-js": () => import("./../../../src/templates/appList.js" /* webpackChunkName: "component---src-templates-app-list-js" */),
  "component---src-templates-app-view-js": () => import("./../../../src/templates/appView.js" /* webpackChunkName: "component---src-templates-app-view-js" */),
  "component---src-templates-article-post-js": () => import("./../../../src/templates/articlePost.js" /* webpackChunkName: "component---src-templates-article-post-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-info-page-js": () => import("./../../../src/templates/infoPage.js" /* webpackChunkName: "component---src-templates-info-page-js" */),
  "component---src-templates-landing-page-js": () => import("./../../../src/templates/landingPage.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-press-post-js": () => import("./../../../src/templates/pressPost.js" /* webpackChunkName: "component---src-templates-press-post-js" */),
  "component---src-templates-signup-page-js": () => import("./../../../src/templates/signupPage.js" /* webpackChunkName: "component---src-templates-signup-page-js" */)
}

